import Handorgel from "handorgel";

export default function initAccordion (){
    const elAccordions = document.querySelectorAll('.handorgel');
    if(elAccordions){
        elAccordions.forEach(elAccordion => {
            const accordion = new Handorgel(elAccordion, {
                multiSelectable: false,
            });
            setTimeout(() => {
                accordion.folds[0].close();

                elAccordion.querySelectorAll('.handorgel__header--open').forEach(item => item.classList.remove('handorgel__header--open'))
                window.focus();
            },200); //fermeture de tous les onglets
        });
    }
}
