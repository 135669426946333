import initCarrousel from './carrousel';

export default function initTabs() {


    const tabsProgram = document.querySelectorAll('[data-target-program]');
    // const tabs = document.querySelectorAll('[data-program]');

    // tabs.forEach(tab => scrollTop());

    tabsProgram.forEach(tab => {

        tab.addEventListener('click', () => {
            const tabId = tab.getAttribute('data-target-program');
            const contentProgram = document.querySelector(`[data-tabs-program-content="program-${tabId}"]`);

            scrollTop();

            toggleActiveTabs(tab, 'offre--active');
            toggleActiveTabs(contentProgram, 'offre__content--active');

            initCarrousel()
        });

    });
}

/**
 * @param tab
 * @param activeClass
 */
function toggleActiveTabs(tab, activeClass = 'offre--active') {
    if (tab == null || activeClass == null || activeClass === '') return;
    if (!tab.classList.contains(activeClass)) {
        const activeTabs = document.querySelector(`.${activeClass}`);
        if (activeTabs) {
            activeTabs.classList.remove(activeClass);
        }
        tab.classList.toggle(activeClass);
    }

}


function toggleActiveTabsContent(tabContent, activeClass = 'offre__content--active') {
    if (tabContent == null || activeClass == null || activeClass === '') return;
    if (tabContent) {
        console.log(tabContent);
        const activeContentElement = document.querySelector(`.${activeClass}`);

        if (activeContentElement) {
            scrollTop();

            hideElement(activeContentElement, activeClass);

            setTimeout(() => {
                showElement(tabContent, activeClass);

            }, 500)


        }
        console.log(tabContent.classList);

    }
}

function scrollTop() {
    window.scrollTo({top: 0, behavior: "smooth"});
}


// Show an element
function showElement(elem, activeClass) {

    // Get the natural height of the element
    const getHeight = function () {
        const height = elem.scrollHeight + 'px'; // Get it's height
        elem.style.display = 'block'; // Make it visible
        elem.style.display = ''; //  Hide it again
        return height;
    };

    const height = getHeight(); // Get the natural height
    elem.classList.add(activeClass); // Make the element visible
    elem.style.height = height; // Update the max-height

    // Once the transition is complete, remove the inline max-height so the content can scale responsively
    window.setTimeout(function () {
        elem.style.height = '';
    }, 350);

};

// Hide an element
function hideElement(elem, activeClass) {

    // Give the element a height to change from
    elem.style.height = elem.scrollHeight + 'px';

    // Set the height back to 0
    window.setTimeout(function () {
        elem.style.height = '0';
    }, 1);

    // When the transition is complete, hide it
    window.setTimeout(function () {
        elem.classList.remove(activeClass);
    }, 350);

};

// Toggle element visibility
function toggleElement(elem, timing) {

    // If the element is visible, hide it
    if (elem.classList.contains('is-visible')) {
        hide(elem);
        return;
    }

    // Otherwise, show it
    show(elem);

};

// // Listen for click events
// document.addEventListener('click', function (event) {
//
//     // Make sure clicked element is our toggle
//     if (!event.target.classList.contains('toggle')) return;
//
//     // Prevent default link behavior
//     event.preventDefault();
//
//     // Get the content
//     var content = document.querySelector(event.target.hash);
//     if (!content) return;
//
//     // Toggle the content
//     toggle(content);
//
// }, false);