//Polyfill
import './modules/polyfill';
import objectFitImages from 'object-fit-images'; //img-object-fit polyfill
// import 'focus-within-polyfill';
import cssVars from 'css-vars-ponyfill';
import Barba from 'barba.js';

//modules
import {isBrowserCanUseCssVariables} from './modules/isfunctions';
// import lazyloadInit from "./modules/lazyLoad";
import initMainNav from "./modules/nav";
import barbaPageTransition from "./modules/barba";
// import initAnimation from "./modules/animation";
import formPostulation from "./modules/addform";
// import animatedCounter from "./modules/counter";
import {initLightBox} from "./modules/lightbox";
import initPopup from "./modules/popup";
import initTabs from "./modules/tabs";
import initAccordion from './modules/accordion';
import carroussel from './modules/carrousel';
import stickyNav from './modules/stickynav';
import scroll from './modules/scroll';

// import "./modules/lazyLoad2";
// import "./modules/prefetch";

// import "./accessibility/skip-link-focus-fix";
barbaPageTransition();
headerAnimation();

function init() {
    stickyNav();
    initPopup();
    // initAnimation();
    initMainNav();
    // lazyloadInit();
    // animatedCounter('.counter__number, .counterp__number');

    initTabs();
    scroll();
    initAccordion();
    carroussel();


    //ajoute la compatibilité des variables css
    if (!isBrowserCanUseCssVariables()) {
        cssVars({

            rootElement: document,
            watch: true,
            variables: {
                '--height': '3px' ,
                '--size': '4rem',
            }
        });
    }


    //Lightbox
    initLightBox();

    formPostulation();




    const someImages = document.querySelectorAll('img.some-image');
    objectFitImages(someImages);
}

//animation du header
function headerAnimation() {
    //Animation Header
    // gsap.from(".brand__logo", {opacity: 0, x: -50, duration: 1});
    // gsap.from(".brand__slogan", {opacity: 0, x: -50, duration: 1, delay: 0.3});
    // gsap.from(".nav__list > .menu-item", {opacity: 0, y: -50, duration: 1, stagger: 0.1});
    // // gsap.from(".header__cta a", {opacity: 0,  y:-50, duration: 1, stagger: 0.1});
    //
    // gsap.from(".header__cta .cta__phone", {opacity: 0, x: -50, duration: 1, delay: 0.3});
    // gsap.from(".header__cta .cta__btn", {opacity: 0, scale: 0, duration: 0.5});
    // gsap.from(".header__cta .social__list a", {opacity: 0, x: -50, duration: 1, stagger: 0.1});
    //
    // gsap.from(".burger", {opacity: 0, x: 50, duration: 1});
}


init();

// ONT INIT LORSQU'IL Y A DES TRANSITIONS DE PAGE VIA BARBA
Barba.Dispatcher.on('transitionCompleted', function () {
    init();
});
