import Swiper from 'swiper';

/**
 <!-- Slider main container -->
 <div class="swiper-container">
     <!-- Additional required wrapper -->
     <div class="swiper-wrapper">
         <!-- Slides -->
             <div class="swiper-slide">Slide 1</div>
             <div class="swiper-slide">Slide 2</div>
             <div class="swiper-slide">Slide 3</div>
         </div>
     <!-- If we need pagination -->
     <div class="swiper-pagination"></div>

     <!-- If we need navigation buttons -->
     <div class="swiper-button-prev"></div>
     <div class="swiper-button-next"></div>

     <!-- If we need scrollbar -->
     <div class="swiper-scrollbar"></div>
 </div>
 */
export default function initCarrousel() {

    if (document.querySelector('.fullcreen-carrousel')) {
        const carrousel = new Swiper('.fullcreen-carrousel', {
            direction: 'horizontal',
            slidesPerView: 1,

            loop: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            speed: 700,
            spaceBetween: 0,
            navigation: {
                nextEl: '.fullcreen-carrousel .swiper-button-next',
                prevEl: '.fullcreen-carrousel .swiper-button-prev',
            },
            pagination: {
                el: '.fullcreen-carrousel .swiper-pagination',
                type: 'bullets',
                clickable: true
            },
        });
        carrousel.autoplay.start();
    }


    const incubeSliders = document.querySelectorAll('.container-incube--slider');
    if (incubeSliders) {
        incubeSliders.forEach((slider, index) =>{
            slider.style.width = '100%';
            console.log(slider.style.width);
            incubeSlider(`.incube-slider-${index}`);
        })

    }

    function incubeSlider($slider = '.container-incube--slider'){
        const carrouselIncubateur = new Swiper($slider, {
            direction: 'horizontal',
            updateOnWindowResize:true,
            slidesPerView: 4,
            spaceBetween: 20,
            autoplay: 4500,
            loop: true,
            speed: 700,
            // Responsive breakpoints
            breakpoints: {
                // when window width is <= 320px
                // when window width is <= 480px
                0: {
                    slidesPerView: 1,
                    spaceBetween: 20

                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                // when window width is <= 640px
                1000: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                1400:{
                    slidesPerView: 4,
                    spaceBetween: 20

                }
            },
            navigation: {
                nextEl: '.container-incube--slider .swiper-button-next',
                prevEl: '.container-incube--slider .swiper-button-prev',
            },
            // pagination: {
            //     el: '.container-incube--slider .swiper-pagination',
            //     type: 'bullets',
            //     clickable: true,
            //     // dynamicBullets:true,
            //     // dynamicMainBullets: 3
            // },
        });

        carrouselIncubateur.autoplay.start();
        if (carrouselIncubateur.originalParams.loop && carrouselIncubateur.loopedSlides < carrouselIncubateur.originalParams.slidesPerView) {
            carrouselIncubateur.params.slidesPerView = carrouselIncubateur.loopedSlides;
            carrouselIncubateur.destroy(false, false);
            carrouselIncubateur.init();
        }
        // carrouselIncubateur.init();
    }
}