
export default function scroll (){
    const elAnchors = document.querySelectorAll('[data-scroll-target]');
    if(elAnchors){
        elAnchors.forEach(elAnchor => {
            elAnchor.addEventListener('click', (e) => {
                e.preventDefault();
                const targetId = e.target.getAttribute('data-scroll-target');
                const elTarget = document.getElementById(targetId);
                console.log(targetId);
                console.log(elTarget);
                if(elTarget){
                    const {y} = elTarget.getBoundingClientRect();
                    const {height:headerPos} = document.querySelector('.header').getBoundingClientRect();


                    window.scrollTo({top:y - headerPos, behavior:'smooth'});
                    // window.scrollTo(0, y - 150);
                }
            })
        });
    }
}
