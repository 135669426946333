/**
 * Vérifie si l'utilisateur a des préférences système / navigateur concernant les effets de mouvement.
 * @returns {boolean}
 */
function isPrefersReducedMotion() {
    if (!'matchMedia' in window) {
        return false;
    }

    const motionQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    if (!motionQuery.matches) {
        return false;
    }

    return true;
}

/**
 * Vérifie si l'utilisateur est sur mobile
 * @returns {boolean}
 */
function isMobile() {
    if (navigator.userAgent.match(/Mobi/)) {
        return true;
    }

    if ('screen' in window && window.screen.width < 1366) {
        return true;
    }

    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if (connection && connection.type === 'cellular') {
        return true;
    }

    return false;
}

/**
 * Détecte si l'utilisateur est sur internet explorer
 * @returns {boolean}
 */
function isIeVersion() {

    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    return (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number

}

/**
 * Détecte si l'utilisateur est sur safari
 * @returns {boolean}
 */
function isSafari(){
    const ua = window.navigator.userAgent;
    return /^((?!chrome|android).)*safari/i.test(ua)
}

/**
 * Vérifie si l'utilisateur est sur mobile ou a des préférences.
 * @returns {boolean}
 */
function isMobileAndReduceMotion() {
    return (isPrefersReducedMotion() && isMobile())
}

/**
 * Vérifie les variables css sont supporté
 * @returns {CSS | boolean}
 */
function isBrowserCanUseCssVariables() {
    return window.CSS && CSS.supports('color', 'var(--fake-var)');
}


export {
    isBrowserCanUseCssVariables,
    isPrefersReducedMotion,
    isMobile,
    isMobileAndReduceMotion,
    isIeVersion,
    isSafari
}