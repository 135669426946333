import Barba from 'barba.js';

export function pageTransition() {
    // const tl = gsap.timeline();
    //
    // tl.to('.transition',{duration: 0.3, opacity:1});
    // tl.to('.transition',{duration: 0.3, opacity:0, delay:0.5});
    //
    //
}




export default function barbaInit() {
    /**
     *  Mise à jour du bodyClass dans le <body>
     **/
    var originalFn = Barba.Pjax.Dom.parseResponse;
    Barba.Pjax.Dom.parseResponse = function (response) {
        response = response.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', response);
        var bodyClasses = $(response).filter('notbody').attr('class');
        $('body').attr('class', bodyClasses);
        return originalFn.apply(Barba.Pjax.Dom, arguments);
    };
    /**
     *  Pour cancel le reload quand on ai sur la meme page
     **/
    var links = document.querySelectorAll('a[href]');
    var cbk = function (e) {
        if (e.currentTarget.href === window.location.href) {
            e.preventDefault();
            e.stopPropagation();
        }
    };
    for (var i = 0; i < links.length; i++) {
        links[i].addEventListener('click', cbk);
    }
    /**
     *  Processus pour changer à la transition
     *  Par défaut, la balise META dans la tête ne change pas. (La balise de titre changera.)
     *  De plus, js dans le barba-container n'est pas exécuté, alors écrivez le processus que vous souhaitez modifier / exécuter individuellement.
     **/
    Barba.Dispatcher.on('newPageReady', function (currentStatus, oldStatus, barbaContainer, newPageRawHTML) {
        if (Barba.HistoryManager.history.length === 1) {  // Première vue
            return; // Aucune mise à jour n'est nécessaire pour le moment
        }


        //Mettre à jour les heads
        var $newPageHead = $('<head />').html(
            $.parseHTML(
                newPageRawHTML.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0],
                document,
                true
            )
        );
        // Tag que vous souhaitez modifier (Veuillez modifier en fonction de votre environnement)
        var headTags = [
            "link[rel='canonical']",
            "link[rel='shortlink']",
            "link[rel='alternate']",
            "meta[name='description']",
            "meta[property^='og']",
            "meta[name^='twitter']",
            "meta[name='robots']",
            "style[data-type='vc_shortcodes-custom-css']",
        ].join(',');
        $('head').find(headTags).remove(); // Supprimer le tag
        $newPageHead.find(headTags).appendTo('head'); // Ajouter un tag
        //Google Analytics
        if (typeof ga === 'function') {
            ga('send', 'pageview', location.pathname);
        }
    }); // Fin Dispatcher

    var FadeTransition = Barba.BaseTransition.extend({
        start: function () {
            Promise
                .all([this.newContainerLoading, this.fadeOut()])
                .then(this.fadeIn.bind(this));
        },
        fadeOut: function () {
            var deferred = Barba.Utils.deferred();
            TweenMax.to(this.oldContainer, 1, {
                opacity: 1,
                delay: 0,
                onComplete: function () {
                    deferred.resolve();
                    $(this.oldContainer).remove();
                }
            });
            return deferred.promise;

        },
        fadeIn: function () {
            var _this = this;
            var $el = $(this.newContainer);
            $(window).scrollTop(0);
            TweenMax.set($(this.oldContainer), {display: "none"});
            TweenMax.set($el, {visibility: "visible", opacity: 0,});
            TweenMax.to($el, 1, {
                opacity: 1,
                onComplete: function () {
                    _this.done();
                }
            });
        }
    });
    Barba.Pjax.getTransition = function () {
        return FadeTransition;
    };

    var Homepage = Barba.BaseView.extend({
        namespace: 'homepage',
        onEnter: function () {
            // The new Container is ready and attached to the DOM.
            const burger = document.querySelector('.burger');
            const nav = document.querySelector('.nav');
            burger.classList.remove('burger--is-active');
            nav.classList.remove('nav--is-active');

        },
        onEnterCompleted: function () {
            // The Transition has just finished.
        },
        onLeave: function () {
            // A new Transition toward a new page has just started.

        },
        onLeaveCompleted: function () {
            // The Container has just been removed from the DOM.
            pageTransition();
        },

    });
// Don't forget to init the view!
    Homepage.init();


    // Don't forget to init the view!
    /**
     * BARBA INIT
     **/
    Barba.Pjax.Dom.wrapperId = 'MMwrapper';
    Barba.Pjax.Dom.containerClass = 'MMcontainer';
    Barba.Pjax.start();
    Barba.Prefetch.init();

}