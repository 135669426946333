export default function initPopup() {

    const btnPopups = document.querySelectorAll('[data-select-popup]');

    btnPopups.forEach(btnPopup => {

        const selectPopup = btnPopup.getAttribute('data-select-popup');
        //click sur les bouton de popup
        btnPopup.addEventListener('click', e => {
            const popup = document.querySelector(`[data-popup="${selectPopup}"]`);

            //vérifie s'il y a un popup à ce nom
            if (popup) {

                if (selectPopup === 'form') {
                    popup.querySelector('input').focus();
                } else {
                    // const iframe = popup.querySelector('iframe');
                    // const iframeInner = iframe.contentDocument ? iframe.contentDocument: iframe.contentWindow.document;
                    // iframeInner.querySelector('#showcase-play').click();
                    //
                }
                const closeBtnPopup = popup.querySelector(".popup__close");

                //ouverture du popup et de ces fonctionnalités
                popup.classList.add('popup--active');
                closeBtnPopup.addEventListener("click", closePopup);

                //fermeture du popup
                popup.addEventListener("click", function (e) {
                    if (e.target.classList.contains("popup--active")) {
                        closePopup();
                    }
                    e.stopPropagation();

                });

                //fermeture du popup avec la touche ESC
                document.addEventListener("keyup", function (e) {
                    if (e.key === "Escape" && popup) {
                        closePopup();
                        e.stopPropagation();
                    }
                });

                function closePopup() {
                    popup.classList.remove("popup--active");
                }
            }

        });

    });

}
