export default function stickyNav() {

    // When the user scrolls the page, execute myFunction
    window.onscroll = function () {
        toggleStickyNavClass();
        toggleScrollTop();
    };

    // Get the navbar
    const elNavbar = document.querySelector(".header");
    const elScrolltop = document.querySelector(".scroll-top");

    const stickyNavPosition = 200;
    const stickyScrollTopPosition = 600;

    // Get the offset position of the navbar

    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function toggleStickyNavClass() {
        if (window.pageYOffset >= stickyNavPosition) {
            elNavbar.classList.add("header--sticky");

        } else {
            elNavbar.classList.remove("header--sticky");
        }
    }

    /**
     * Ajouter un scroll top
     */
    function toggleScrollTop() {
        if (window.pageYOffset >= stickyScrollTopPosition) {
            if (!elScrolltop.classList.contains('scroll-top--active')) {

                elScrolltop.classList.add("scroll-top--active");
                elScrolltop.addEventListener('click', scrollTop);
            }

        } else {
            elScrolltop.classList.remove("scroll-top--active");
            elScrolltop.removeEventListener('click', scrollTop);

        }

    }

    function scrollTop(e) {

        window.scrollTo({top:'0px', behavior:'smooth'});
    }
}
