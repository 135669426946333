/**
 * Permet d'exécuter une function 1 seule fois à toutes les X délais voulu au minimum.
 * @param fn
 * @param delay
 * @returns {Function}
 */
const throttle = (fn, delay=500) =>{
    let last = 0;
    return (...args) => {
        const now = new Date().getTime();
        //vérifie s'il y a déjà eu une éxécution de la function
        if( now - last < delay){
            return;
        }
        last = now;
        return fn(...args);
    };
};
/**
 * Empêche l'exécution multiple de la function tant que le délai initialement n'aura pas été passé, chacune nouvelle tentative d'exécution de la function réinitialise le délai.
 * @param fn
 * @param delay
 * @returns {Function}
 */
const debounce = (fn, delay=500) =>{
    let timeoutID;
    return (...args) => {
        if(timeoutID){
            clearTimeout(timeoutID);
        }
        timeoutID = setTimeout( () => {
            fn(...args);
        }, delay);
    };
};
export {
    throttle,
    debounce
}