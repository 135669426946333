import {disablePageScroll, enablePageScroll, addFillGapTarget} from 'scroll-lock';
import {debounce} from "./performance";

function initMainNav() {

    //variables
    const $burgerActiveClass = 'burger--is-active'; //class pour indiquer que le burger menu est actif
    const $navActiveClass = 'nav--is-active'; //class du container actif de la nav
    const $widthBreakPoint = 1094; //en px détermine le breakpoint de lorsque l'on utilise un burger menu ou non

    const $burger = document.querySelector('.burger');
    const $nav = document.querySelector('.nav');
    const $navLinks = document.querySelectorAll('.menu-item a');

    // EventListener
    $burger.addEventListener('click', toggleNav);


    // fermeture du menu via la touche ESC
    document.addEventListener("keyup", (e) => {
        if ((e.keyCode === 27 || e.key === 'Escape' || e.key === "Esc") && $burger.classList.contains($burgerActiveClass)) {
            toggleNav();
            e.stopPropagation();
       }
    });

    // si focus sur un lien du menu, ouvre le menu
    $navLinks.forEach(navLink => {
        // navLink.addEventListener('focus', toggleNav);
        // navLink.addEventListener('focusout', toggleNav);
    });



    /**
     * Active ou désactive le menu
     */
    function toggleNav() {
        $nav.classList.toggle($navActiveClass);
        $burger.classList.toggle($burgerActiveClass);
        toggleBurgerLabel();
        toggleScrollLock();
    }

    /**
     * affiche le aria-label pour les accessibilité
     */
    function toggleBurgerLabel() {
        if ($burger.classList.contains($burgerActiveClass)) {
            $burger.setAttribute('aria-label', 'Ouverture du menu');
            $burger.setAttribute('aria-expanded', 'false');
        } else {
            $burger.setAttribute('aria-label', 'Fermeture du menu');
            $burger.setAttribute('aria-expanded', 'true');

        }
    }


    /**
     * Active ou désactive le scroll
     * @param $fillGapElement
     */
    function toggleScrollLock($fillGapElement = document.querySelector('body')) {
        //
        // //active ou désactive le scroll
        // if ($burger.classList.contains($burgerActiveClass)) {
        //
        //     const $fillGapElement = document.querySelector('body');
        //     disablePageScroll();
        //     addFillGapTarget($fillGapElement);
        //
        //     //Si l'utilise appuie sur le burger menu mais que celui-ci agrandi l'écran désactive le scroll lock et le menu pour s'il revient en mobile
        //     window.addEventListener('resize', debounce((e) => {
        //
        //         if (e.target.innerWidth >= $widthBreakPoint) {
        //             toggleNav();
        //             enablePageScroll();
        //         }
        //     }, 200));
        // } else {
        //     enablePageScroll();
        // }
    }
}


export default initMainNav;
